import fetch from 'node-fetch';
import dotenv from 'dotenv'

// 設定値を読み取る
dotenv.config()

/**
 * 認証情報取得例外クラス
 * @param {string} errorCode エラーコード
 * @param {string} message エラーメッセージ
 */ 
class AuthServiceError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = 'AuthServiceError';
    this.errorCode = errorCode;
  }
}


/**
 * 認証情報取得クラス 
 */  
class AuthClient{
  /**
   * @brief 入力値のチェックを行う。
   * @param {json} input 
   * @returns {void} 
   * @throws {AuthServiceError} 入力値不正のエラーコードとメッセージをスローする。
   */ 
  checkInput(input){
    for (const item in input) {
      if(input[item] === ''){
        throw new AuthServiceError('001','Invalid request parameter:' + item + '=' + '\'\'');
      }
      else if (input[item] === null){
        throw new AuthServiceError('001','Invalid request parameter:' + item + '=null');
      }
      else if (input[item] === undefined) {
        throw new AuthServiceError('001','Invalid request parameter:' + item + '=undefined');
      }
    }
  }

  /**
   * @brief 入力パラメータscopeのバリデーションチェックを行う。
   * @param {string} scope 
   * @returns {void} 
   * @throws {AuthServiceError} 入力値不正のエラーコードとメッセージをスローする。
   */ 
  checkScopeValidate(scope){
    // scope形式不正チェック
    if(scope.match('.+/.+') == null){
      throw new AuthServiceError('001','Invalid request parameter: scope is invalid');
    }
  }

  /**
   * アクセストークンを取得する
   * @param {string} clientId : クライアントID
   * @param {string} clientSecret : クライアントシークレット
   * @param {string} scope  : スコープ
   * @param {string} cognitoDomain : 認証基盤ドメイン
   * @param {string} timeout_ms : 設定値から読み取ったタイムアウト秒数
   * @returns {json} cognitoから取得した認証情報(正常時)
   * @throws {AuthServiceError} 通信結果のエラーコードとメッセージをスローする。
   */ 
  async getAccessToken(clientId,clientSecret,scope,cognitoDomain, timeout_ms){
  

    // CognitoトークンエンドポイントURL作成
    const url = `https://${cognitoDomain}/oauth2/token`;

    const basic = 'Basic ' + Buffer.from(clientId + ':' + clientSecret).toString('base64');

    const data = new URLSearchParams()
    data.append('grant_type', 'client_credentials')
    data.append('scope', scope)

    let parsed = parseInt(timeout_ms);
    if (isNaN(parsed)) {
      timeout_ms = process.env.TIME_OUT;
    }

    const options = {
      method: 'POST',
      body:    data,
      headers: { 
        'Content-type': 'application/x-www-form-urlencoded',
        'Authorization': basic
      },
      signal:AbortSignal.timeout(parseInt(timeout_ms))
    }
    // Cognitoトークン取得 リクエスト送信
    let result = {}
    result = await fetch(url, options)
      .then(res=> Promise.all([res.status, res.json()]))
      .then(([status, json])=>{
        if (status === 200){
          return {
            accessToken: json['access_token'],
            tokenType: json['token_type'],
            expiresIn: json['expires_in']
          }
        }
        // ステータスが200(正常)でない場合
        else if (status !== 200){
          throw new AuthServiceError('002','Communication error');
        }
      })
      .catch(error => {
        // 接続エラー発生時、002エラーにthrowする
        if (error.errorCode==="002"){
          throw new AuthServiceError('002','Communication error');
        }
        else if(error.name === 'FetchError'){

          // リクエストがタイムアウトした場合: 003エラーにthrowする
          if((error.message.toString()).match('^network timeout') != null){
            throw new AuthServiceError('003','Request Timeout');
          }
          // タイムアウト以外のFetchErrorは002エラー(通信失敗)とする
          else{
            throw new AuthServiceError('002','Communication error');
          }

        } 
        // リクエストがタイムアウト秒数を超え中断した場合: 003エラーにthrowする
        else if (error.name === 'AbortError') {
          throw new AuthServiceError('003','Request Timeout');
        }
        // 002,003以外のエラー時に、999エラーにthrowする
        else{
          throw new AuthServiceError('999','Unexpected error:' + error);
        }
      })
      return result
  }

  /**
   * 認証基盤からアクセストークンなどの認証情報を取得する関数。
   * @param {string} clientId : クライアントID
   * @param {string} clientSecret : クライアントシークレット
   * @param {string} scope  : スコープ
   * @param {string} cognitoDomain : 認証基盤ドメイン
   * @returns {json} tokenInfo: 取得した認証情報
   */ 
  async requestAccessToken(clientId,clientSecret,scope,cognitoDomain) {
    try {
      const input = {
        clientId: clientId,
        clientSecret: clientSecret,
        scope: scope,
        cognitoDomain: cognitoDomain
      };
      // 空文字/null/undefinedチェックを行う。
      this.checkInput(input)
      // スコープのバリデーション確認を行う。
      this.checkScopeValidate(scope)
      
      // 設定されたタイムアウト秒数
      const timeout_ms = process.env.TIME_OUT
      
      // トークンを取得する。
      let tokenInfo = await this.getAccessToken(clientId,clientSecret,scope,cognitoDomain,timeout_ms)

      return tokenInfo

    } catch (error) {
      if(error.errorCode==="001"){
        throw new AuthServiceError(error.errorCode,error.message);
      }
      else if (error.errorCode==="002"){
        throw new AuthServiceError(error.errorCode,error.message);
      } 
      else if(error.errorCode==="003"){
        throw new AuthServiceError(error.errorCode,error.message);
      }
      else if(error.errorCode==="999"){
        throw new AuthServiceError(error.errorCode,error.message);
      }
      else{
        throw new AuthServiceError('999','Unexpected error:' + error);
      }
    }
    
  }
}



export {AuthClient, AuthServiceError}